import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/proper-tail-calls",
  "date": "2015-07-21",
  "title": "PROPER TAIL CALLS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "A tail call happens when a function Foo calls a function Bar as its final action. At that point Foo stops and passes the execution to function Bar and disappears not having to do anything from there onwards."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The process of one function calling another may end up creating a new `}<inlineCode parentName="p">{`stack frame`}</inlineCode>{`, if the first function returns a result to the second.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function factorial(n) {
    function recur(n, acc) {
        if (n === 0) {
            return acc;
        } else {
            return recur(n-1, n*acc);
        }
    }
    return recur(n, 1);
}

factorial(100000);
`}</code></pre>
    <p>{`After `}<strong parentName="p">{`factorial`}</strong>{` calls `}<strong parentName="p">{`recur`}</strong>{`, it has nothing else to do. In such situations, the program does not need to return to the calling function when the called function ends. Therefore, after the tail call, the program does not need to keep any information about the calling function in the stack.
ES6 takes advantage of this fact and actually do not use any extra stack space when doing a tail call. We say that those implementations support proper tail calls (PTC).`}</p>
    <Message type="info" title="Tail call & tail-recursive" content="In computer science, a tail call is a subroutine call performed as the final action of a procedure. If a tail call might lead to the same subroutine being called again later in the call chain, the subroutine is said to be tail-recursive, which is a special case of recursion.
  - Wikipedia" mdxType="Message" />
    <p>{`Because a proper tail call uses no stack space, there is no limit on the number of "nested" tail calls that a program can make. For instance, we can call the following function with any number as argument; it will never overflow the stack:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo(n) {
  if (n > 0) {
    return foo(n - 1);
  }
}
`}</code></pre>
    <h2>{`What qualifies to be a tail call?`}</h2>
    <p>{`A subtle point when we use proper tail calls is what is a tail call. Some obvious candidates fail the criteria that the calling function has nothing to do after the call. For instance, in the following code, the call to `}<strong parentName="p">{`bar`}</strong>{` is not a tail call:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo(n) {
  bar(n);
  return;
}
`}</code></pre>
    <p>{`The problem in that example is that, after calling `}<strong parentName="p">{`bar`}</strong>{`, `}<strong parentName="p">{`foo`}</strong>{` still has to discard occasional results from `}<strong parentName="p">{`bar`}</strong>{` before returning. Similarly, all the following calls fail the criteria:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`return bar(n) + 1; // must do the addition
return n || bar(n);    // must adjust to 1 result
return n && bar(n);       // must adjust to 1 result
`}</code></pre>
    <p>{`Here is another scenario:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(...values) {
  return values.shift() + sum(...values);
}
`}</code></pre>
    <p>{`In the above scenario `}<inlineCode parentName="p">{`+`}</inlineCode>{` operation is executed `}<inlineCode parentName="p">{`after`}</inlineCode>{` the recursive call, which disqualifies it to be a proper tail call (PTC).`}</p>
    <p>{`To change the above function to make a proper tail call, introduce a helper function with an `}<inlineCode parentName="p">{`accumulator`}</inlineCode>{` parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(...values) {
   function sumTo(acc, values) {
       return sumTo(acc + values.shift(), values); // tail-recursive call
   }
   return sumTo(0, values);
 }
`}</code></pre>
    <Message type="info" title="Currying" content="Calling a function within a function with different parameters is called `currying`." mdxType="Message" />
    <p>{`Without proper tail calls, each user move would create a new stack level. After some number of moves, there would be a stack overflow. With proper tail calls, there is no limit to the number of moves that a user can make, because each move actually performs a goto to another function, not a conventional call.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      